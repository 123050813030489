<script>
  import { createEventDispatcher } from 'svelte';

  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';

  const dispatch = createEventDispatcher();

  function handleSubmit() {
    dispatch('notificationConfirmation');
  }
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <p class="text">お振込みいただく金額は、必ずお振込み先の口座への振込を完了させてください</p>
    <p class="text">お振込み金額の着金が確認された後、お客さまの口座へ反映されます。</p>
  </div>
  <LoginButtonsGroup on:back />
</form>

<style>
  .text {
    margin: 20px;
  }
</style>
