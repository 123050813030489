<script>
  import CloseCircle from 'svelte-material-icons/CloseCircle.svelte';
  import { general as generalStrings } from '../../resources/strings';

  export let globalError = null;
  const color = '#b71c1c';
</script>

<CloseCircle width="100px" height="100px" {color} />
<h3 class="heading" style="color: {color}">
  {generalStrings.transactionFailure}
</h3>
<p class="message">
  {globalError.message}
</p>
{#if globalError.pendings}
  <ul>
    {#each globalError.pendings as pending}
      <li class="list-item">
        {pending}
      </li>
    {/each}
  </ul>
  <p class="message">保留中の決済を完了後、新しい振込を行って下さい。</p>
{/if}

<style>
  .heading {
    font-size: 2rem;
    text-align: center;
  }

  .message {
    font-size: 1.3rem;
    text-align: center;
    padding: 0 2rem;
    overflow-wrap: break-word;
  }

  .list-item {
    font-size: 1.2rem;
  }
</style>
