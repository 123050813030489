<script>
  import { fly } from 'svelte/transition';
  import LogRocket from 'logrocket';

  import { generalError } from '../stores/error.store';
  import { resetSelectedBank } from '../stores/selected-bank.store';
  import { state } from '../stores/state.store';
  import { transaction } from '../stores/transaction.store';

  import Card from '../components/Card.svelte';
  import Timer from '../components/Timer.svelte';
  import SelectedBank from './SelectedBank.svelte';

  import { errors, general as generalStrings } from '../resources/strings';
  import Button from '../components/Button.svelte';

  let ftdUserNotice = $transaction.userFlow === 'FTD';

  function handleNoticeConfirmation() {
    ftdUserNotice = false;
  }

  function handleTimeout() {
    if ($state.transactionResult === '' && $generalError === '') {
      $generalError = errors.timeout;
      LogRocket.track('Timeout');
    }
  }
</script>

<Card>
  {#if ftdUserNotice}
    <div class="notification-container" in:fly={{ x: 200, duration: 300 }}>
      {#each generalStrings.notificationTexts() as text}
        <p class="notification-text">{text}</p>
      {/each}
      <div class="button-container">
        <Button on:click={handleNoticeConfirmation}>
          {generalStrings.next}
        </Button>
      </div>
    </div>
  {:else}
    <Timer on:timeout={handleTimeout} />
    <SelectedBank on:back={() => resetSelectedBank()} />
  {/if}
</Card>

<style>
  .notification-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  .notification-text {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .button-container {
    align-self: end;
  }
</style>
